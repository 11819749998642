import React, { FC, useEffect, useState } from 'react';
import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import ASSETS from '../../assets';
import FilePicker from '../../components/FilePicker/FilePicker';
import { IAttessation, IStepperConfig } from '../../types/interfaces';
import Stepper from '../../components/Stepper/Stepper';
import { shortenAddress, sleep } from '../../utils';
import { twMerge } from 'tailwind-merge';
import DocIcon from '../../assets/DocIcon';
import DocPreview from '../../components/DocPreview/DocPreview';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { compareDocument } from '../../http';
interface IProps {
  authorizedUser?: boolean;
}

const CONFIG: IStepperConfig[] = [
  {
    text: 'Uploading',
    successText: 'Upload',
    finished: false,
  },
  {
    text: 'Comparing',
    successText: 'Compared',
    finished: false,
  },
  {
    text: 'Confirming',
    successText: 'Confirmed',
    finished: false,
  },
  {
    text: 'Results',
    successText: 'Results',
    finished: false,
  },
];

const CompareDoc: FC<IProps> = ({ authorizedUser = false }) => {
  const { selectedAttestation } = useSelector(
    (state: RootState) => state.attesation
  );

  const [file, setFile] = useState<File | null>(null);
  const [show, setShow] = useState(false);
  const [docUploaded, setDocUploaded] = useState(false);
  const [attesationMatched, setAttesationMatched] = useState(false);
  const [attesationNotMatched, setAttesationNotMatched] = useState(false);
  const [attestation, setAttestation] = useState<IAttessation | null>(null);
  const [newDocHash, setNewDocHash] = useState('');

  const [active, setActive] = useState(0);
  const [config, setConfig] = useState<IStepperConfig[]>(CONFIG);

  const handlePickFile = () => {
    setShow(true);
  };

  const handleColor = () => {
    if (attesationMatched) {
      return '#248A3D';
    } else if (attesationNotMatched) {
      return '#D70015';
    } else {
      return '#086DE1';
    }
  };

  const handleRemoveDoc = () => {
    setActive(0);
    setAttesationMatched(false);
    setFile(null);
    setDocUploaded(false);
    setAttesationNotMatched(false);
  };

  const handleProcessDoc = async () => {
    try {
      const formData = new FormData();
      if (file && attestation) {
        formData.append('UID', attestation?.UID);
        formData.append('file', file);
      }

      const { data } = await compareDocument(formData);
      setConfig(prev => {
        const newConfig = [...prev];
        newConfig[0].finished = true;
        return newConfig;
      });
      setDocUploaded(true);
      setActive(1);
      await sleep(2000);
      setConfig(prev => {
        const newConfig = [...prev];
        newConfig[1].finished = true;
        return newConfig;
      });
      setActive(2);
      await sleep(2000);
      setConfig(prev => {
        const newConfig = [...prev];
        newConfig[2].finished = true;
        return newConfig;
      });
      setActive(3);
      await sleep(2000);
      setConfig(prev => {
        const newConfig = [...prev];
        newConfig[3].finished = true;
        return newConfig;
      });
      setNewDocHash(data.hash);
      if (data.documentComparisonResult) {
        setAttesationMatched(true);
      } else {
        setAttesationNotMatched(true);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
    }

    // setAttesationNotMatched(true);
  };

  useEffect(() => {
    if (file && attestation) {
      setActive(0);
      setConfig(CONFIG.map(i => ({ ...i })));
      handleProcessDoc();
      setDocUploaded(false);
      setAttesationMatched(false);
      setAttesationNotMatched(false);
    }
  }, [file, attestation]);

  useEffect(() => {
    if (selectedAttestation) {
      setAttestation(selectedAttestation);
    }
  }, [selectedAttestation]);

  return (
    <div className='flex-grow bg-white shadow-auth_box rounded-[15px] p-[50px] flex mb-[32px] gap-[25px] relative'>
      <div
        className={twMerge(
          'flex-grow flex flex-col max-w-[415px] relative',
          docUploaded && 'gap-2',
          authorizedUser && 'flex-[0_0_50%] max-w-full'
        )}
      >
        {(attesationMatched || attesationNotMatched) && (
          <>
            {attesationMatched && (
              <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[82px] w-[82px] rounded-full bg-success_gradient z-30 grid place-items-center'>
                <img src={ASSETS.tick} alt='' />
              </div>
            )}
            {attesationNotMatched && (
              <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[82px] w-[82px] rounded-full bg-error_gradient z-30 grid place-items-center'>
                <img src={ASSETS.cross_large} alt='' />
              </div>
            )}
          </>
        )}
        {!docUploaded ? (
          <>
            {!authorizedUser && (
              <div className='px-5 bg-white h-[55px] flex items-center justify-between border border-k-border rounded-t-[12px]'>
                <Typography variant='body1' className='font-medium'>
                  Preview available to KPMG users.
                </Typography>
                <Button
                  variant='outlined'
                  label='Log In'
                  className='min-w-[69px] h-[35px] text-sm'
                />
              </div>
            )}
          </>
        ) : (
          <div className='flex-grow h-0 overflow-hidden bg-k-grey-200 rounded-t-[12px] relative'>
            <div className='absolute top-3 left-3 rounded-[5px] bg-[#00000099] py-2 px-[10px] text-white z-20'>
              <span className='text-sm font-[660]'>Uploaded Document</span>
            </div>
            {!authorizedUser && (
              <div className='absolute left-5 w-[calc(100%_-_40px)] h-[calc(100%_-_20px)] backdrop-blur-[5px]'></div>
            )}
            {file && docUploaded && (
              <iframe
                title='doc'
                src={URL.createObjectURL(file) + '#toolbar=0'}
                className='w-full h-full pt-[22px] px-[22px] bg-transparent border-none outline-none'
              ></iframe>
            )}
          </div>
        )}

        <div className='flex-grow h-0 overflow-hidden bg-k-grey-200 rounded-b-[12px] relative'>
          <div className='absolute top-3 left-3 rounded-[5px] bg-[#00000099] py-2 px-[10px] text-white z-20'>
            <span className='text-sm font-[660]'>Attestation</span>
          </div>
          {!authorizedUser && (
            <div className='absolute left-5 w-[calc(100%_-_40px)] h-[calc(100%_-_20px)] backdrop-blur-[5px]'></div>
          )}
          {/* <div className='bg-white h-full p-4'>
            hi Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Reiciendis accusantium iure suscipit quis nemo odit reprehenderit
            iste impedit consequuntur vitae voluptatem incidunt nihil, neque
            quaerat doloribus minus deserunt voluptas, dignissimos natus
            excepturi commodi qui. Placeat quam nisi sequi, est in a? Quos
            voluptas molestias eius blanditiis error minima esse at facilis,
            saepe repudiandae nam impedit minus excepturi porro? Aliquid nostrum
            odio at suscipit! Velit dolorum dicta tempora error quaerat
            accusamus molestiae quidem cumque in praesentium maiores quod
            consectetur pariatur iure rem natus, vero unde exercitationem fuga.
            Saepe consectetur natus esse qui architecto omnis vero quidem
            eveniet veniam, impedit est cum cumque tempora commodi itaque
            recusandae tenetur magni cupiditate. Magnam quas rem, minus quia
            voluptatem harum quo impedit fugiat quisquam exercitationem sunt
            ullam eum officiis modi ipsa ea laboriosam fuga, doloremque laborum,
            veniam placeat obcaecati itaque? Adipisci animi dolores temporibus
            distinctio itaque, minima nobis nesciunt! Ipsam asperiores dicta,
            saepe provident optio vero voluptatum aliquam, officiis ullam
            eveniet ipsum, blanditiis repellendus voluptatibus! Nam voluptatum
            perferendis ducimus perspiciatis iste asperiores temporibus
            architecto tempora dolore fugit voluptates omnis, fuga accusamus
            consectetur. Qui illo harum deserunt mollitia dignissimos veritatis
            est fuga minima minus sapiente, repellendus aut voluptatum debitis
            unde, culpa ea nobis reprehenderit. Temporibus, excepturi earum
            officiis ipsum laudantium provident ab totam suscipit laborum
            incidunt iste voluptatibus nesciunt reiciendis ad. Ad nam esse unde
            dolorum aliquid dolores magnam molestias animi! Laudantium sequi
            aliquid eveniet ipsa minima? Sint, quam qui nostrum accusamus culpa
            non voluptas perspiciatis molestiae dolores a earum cumque, velit
            natus nesciunt voluptatum provident asperiores! Mollitia delectus
            repellat labore doloremque ex quidem odio sequi, est voluptatum,
            illum iste veniam dignissimos non, explicabo ipsam. In, rerum!
            Obcaecati vitae inventore ullam. Dolore velit ducimus dolorum
            praesentium veritatis minima consequatur, aliquam omnis ad! Quia
            debitis minus, labore perferendis rerum, quaerat, ipsam suscipit
            iste amet corporis ipsum. Eaque?
          </div> */}
          {attestation?.document && <DocPreview url={attestation.document} />}
        </div>
      </div>
      <div
        className={twMerge(
          'flex-grow flex flex-col',
          authorizedUser && authorizedUser && 'flex-[0_0_50%] max-w-full'
        )}
      >
        <Typography variant='h2' className='title leading-10'>
          Compare Your <br /> Document
        </Typography>
        {file ? (
          <>
            {attesationMatched || attesationNotMatched ? (
              <>
                <Typography
                  variant='h4'
                  className={twMerge(
                    'font-medium mt-[15px]',
                    attesationMatched && 'text-k-success',
                    attesationNotMatched && 'text-k-error'
                  )}
                >
                  {attesationMatched && 'Document attestation match!'}
                  {attesationNotMatched && 'Document does not match.'}
                </Typography>
                <div className='flex items-center gap-1 mt-[15px] '>
                  <Typography
                    variant='body1'
                    color='grey'
                    className='font-medium'
                  >
                    Doc Hash:
                  </Typography>
                  {(attesationMatched || attesationNotMatched) && (
                    <>
                      {attesationMatched && (
                        <div className='w-[15px] h-[15px] grid place-items-center bg-success_gradient rounded-full'>
                          <img
                            src={ASSETS.tick_small}
                            className='w-[7px]'
                            alt=''
                          />
                        </div>
                      )}
                      {attesationNotMatched && (
                        <div className='w-[15px] h-[15px] grid place-items-center bg-error_gradient rounded-full'>
                          <img
                            src={ASSETS.cross_large}
                            className='w-[7px]'
                            alt=''
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className='flex items-end gap-1'>
                  <Typography className='font-medium break-words whitespace-pre-line'>
                    {shortenAddress('0x' + newDocHash, 10, 10)}
                  </Typography>
                  <img
                    src={ASSETS.copy_black}
                    className='opacity-50 cursor-pointer'
                    alt=''
                  />
                </div>
              </>
            ) : (
              <div className='mt-[20px] pb-[20px]'>
                <Stepper activeIndex={active} steps={config} />
              </div>
            )}

            <div className='flex items-center justify-between border border-k-border rounded-3xl py-[9px] px-[15px] gap-[10px] mt-[20px]'>
              <div className='flex items-center gap-[10px]'>
                <DocIcon color={handleColor()} />
                <Typography
                  variant='body1'
                  className={twMerge(
                    'text-k-dark-blue font-bold',
                    attesationMatched && 'text-k-success',
                    attesationNotMatched && 'text-k-error'
                  )}
                >
                  {file?.name}
                </Typography>
              </div>
              <div className='flex items-center gap-[10px]'>
                <Typography variant='body1' color='grey'>
                  ({Math.floor((file?.size || 0) / 1024)}k)
                </Typography>
                <img
                  src={ASSETS.close_icon}
                  className='ml-[5px] cursor-pointer'
                  alt=''
                  onClick={handleRemoveDoc}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Typography
              variant='h4'
              className='font-medium leading-[22px] mt-[15px]'
            >
              Upload a document from your local computer to compare with the
              attestation hash.
            </Typography>
            <div className='mt-7 flex items-center gap-[15px]'>
              <Button
                label='Upload to Compare'
                variant='primary'
                leftIcon={ASSETS.upload}
                className='whitespace-nowrap'
                onClick={handlePickFile}
              />
              <Typography variant='body1' color='grey'>
                Drag and drop file here to upload.
              </Typography>
            </div>
          </>
        )}

        <div className='mt-[64px] flex-grow flex flex-col'>
          <div className='mt-auto border-t border-t-k-border pt-[15px]'>
            <Typography className='font-medium'>
              Comparison Attestation
            </Typography>
            <Typography
              variant='body1'
              color='grey'
              className='mt-[5px] font-medium'
            >
              {attesationMatched || attesationNotMatched ? (
                <>
                  {attesationNotMatched &&
                    'Your upload was verified against the following attestation:'}
                  {attesationMatched &&
                    'Your upload was verified against the following attestation:'}
                </>
              ) : (
                'Your upload will be verified against the following attestation:'
              )}
            </Typography>
            <div className='flex items-center gap-1 mt-[15px] '>
              <Typography variant='body1' color='grey' className='font-medium'>
                Doc Hash:
              </Typography>
              {(attesationMatched || attesationNotMatched) && (
                <>
                  {attesationMatched && (
                    <div className='w-[15px] h-[15px] grid place-items-center bg-success_gradient rounded-full'>
                      <img src={ASSETS.tick_small} className='w-[7px]' alt='' />
                    </div>
                  )}
                  {attesationNotMatched && (
                    <div className='w-[15px] h-[15px] grid place-items-center bg-error_gradient rounded-full'>
                      <img
                        src={ASSETS.cross_large}
                        className='w-[7px]'
                        alt=''
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className='flex items-end gap-1'>
              <Typography className='font-medium break-words whitespace-pre-line'>
                {shortenAddress(attestation?.docHash || '', 10, 10)}
              </Typography>
              <img
                src={ASSETS.copy_black}
                className='opacity-50 cursor-pointer'
                alt=''
              />
            </div>

            <Typography
              variant='body1'
              color='grey'
              className='mt-[15px] font-medium'
            >
              UID:
            </Typography>
            <div className='flex items-center gap-1'>
              <Typography className='font-medium break-words whitespace-pre-line '>
                {shortenAddress(attestation?.UID || '', 10, 10)}
              </Typography>
              <img
                src={ASSETS.copy_black}
                className='opacity-50 cursor-pointer'
                alt=''
              />
            </div>
            <Typography
              variant='body1'
              color='grey'
              className='mt-[15px] font-medium'
            >
              Version:
            </Typography>
            <Typography className='font-[660] break-words whitespace-pre-line'>
              {attestation?.lastModified}
            </Typography>
          </div>
        </div>
      </div>
      {show && (
        <div className='absolute w-full h-full left-0 top-0 z-20 p-5'>
          <FilePicker setShow={setShow} setFile={setFile} />
        </div>
      )}
    </div>
  );
};

export default CompareDoc;
