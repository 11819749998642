import React from 'react';
import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';
import ASSETS from '../../../assets';
import WhatIsAttesation from '../../../components/WhatIsAttesation/WhatIsAttesation';

const MyAttesations = () => {
  return (
    <div className='h-full bg-k-grey-bg border border-k-border rounded-[12px] flex flex-col items-center justify-center'>
      <Typography variant='h4'>Nothing to show currently.</Typography>
      <Typography variant='h4'>
        Please add an attestation to get started.
      </Typography>
      <Button
        label='Add New Attestation'
        leftIcon={ASSETS.add_black}
        className='my-[25px]'
      />
      <div className='flex items-center gap-2 cursor-pointer'>
        <WhatIsAttesation />
      </div>
    </div>
  );
};

export default MyAttesations;
