export const sleep = (time = 1000): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

export const shortenAddress = (
  address: string,
  startLength: number = 6,
  endLength: number = 6
): string => {
  if (!address) return '';

  const start = address.slice(0, startLength);
  const end = address.slice(-endLength);

  return `${start}…${end}`;
};
