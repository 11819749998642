import React from 'react';
import { EditorContent } from '@tiptap/react';
import Typography from '../Typography/Typography';

import ASSETS from '../../assets';
import HighlightC from './HighlightC';
import TextColor from './TextColor';
import { useTipTap } from '../../contexts/EditorContext';

const Editor = () => {
  const editor = useTipTap();

  return (
    <div className='h-full flex flex-col '>
      <div className='bg-white p-4 flex flex-wrap gap-4 border-b border-b-k-border justify-center'>
        <div className='flex itemse-center justify-center gap-[16px_10px] flex-wrap [&_img]:cursor-pointer max-w-[330px]'>
          <img
            src={ASSETS.undeo}
            onClick={() => editor?.chain().undo().focus().run()}
            alt=''
          />
          <img
            src={ASSETS.redo}
            onClick={() => editor?.chain().redo().focus().run()}
            alt=''
          />
          <img
            src={ASSETS.bold}
            onClick={() => editor?.chain().toggleBold().focus().run()}
            className='ml-4'
            alt=''
          />
          <img
            src={ASSETS.italic}
            onClick={() => editor?.chain().toggleItalic().focus().run()}
            alt=''
          />
          <img
            src={ASSETS.underline}
            onClick={() => editor?.chain().toggleUnderline().focus().run()}
            alt=''
          />
          <img
            src={ASSETS.strike}
            onClick={() => editor?.chain().toggleStrike().focus().run()}
            alt=''
          />
          {editor && <HighlightC editor={editor} />}
          {editor && <TextColor editor={editor} />}
          <img
            src={ASSETS.align_left}
            onClick={() => editor?.chain().focus().setTextAlign('left').run()}
            alt=''
          />
          <img
            src={ASSETS.align_center}
            onClick={() => editor?.chain().focus().setTextAlign('center').run()}
            alt=''
          />
          <img
            src={ASSETS.align_right}
            onClick={() => editor?.chain().focus().setTextAlign('right').run()}
            alt=''
          />
          <img
            src={ASSETS.justify_text}
            onClick={() =>
              editor?.chain().focus().setTextAlign('justify').run()
            }
            alt=''
          />
          <img src={ASSETS.spacing} className='ml-4' alt='' />
          <img
            src={ASSETS.indent_left}
            onClick={() => {
              try {
                editor?.chain().focus().outdent().run();
              } catch (err: any) {}
            }}
            alt=''
          />
          <img
            src={ASSETS.indent_right}
            onClick={() => {
              try {
                editor?.chain().focus().indent().run();
              } catch (err: any) {}
            }}
            alt=''
          />
          <img
            src={ASSETS.remove_style}
            onClick={() => editor?.chain().focus().unsetAllMarks().run()}
            alt=''
          />
        </div>
      </div>
      <div className='flex-grow p-6 overflow-auto h-0'>
        <EditorContent editor={editor} placeholder='Start writing' />
      </div>
      <div className='h-10 bg-white flex items-center justify-between px-4'>
        <Typography variant='body1' color='grey' className='font-medium'>
          {editor?.getText().length} words
        </Typography>
        <img src={ASSETS.edge} alt='' />
      </div>
    </div>
  );
};

export default Editor;
