import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  open: boolean;
  children: React.ReactNode;
}

const DialogLayout: FC<IProps> = props => {
  const { open, children } = props;

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      {open && (
        <div className='fixed w-full h-full left-0 top-0 bg-[#000000A8] z-20'>
          <div className='h-full flex justify-center items-center'>
            {children}
          </div>
        </div>
      )}
    </>,
    document.getElementById('popups')!
  );
};

export default DialogLayout;
