import React, { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from './store';

import Router from './router';
import MagicProvider from './contexts/MagicContext';
import CONSTANTS from './contants';

const App = () => {
  const dispatch = useDispatch<Dispatch>();

  const token = localStorage.getItem(CONSTANTS.token);

  useEffect(() => {
    if (token) {
      dispatch.auth.handleGetCurrentUser();
    }
  }, [token]);

  return (
    <BrowserRouter>
      <MagicProvider>
        <Router />
      </MagicProvider>
    </BrowserRouter>
  );
};

export default App;
