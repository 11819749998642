import React, { useState, useRef, FC } from 'react';

import { twMerge } from 'tailwind-merge';

import DialogLayout from '../DialogLayout';
import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';

import ASSETS from '../../assets';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

const SignatureRequest: FC<IProps> = ({ open, setOpen, onClose }) => {
  const [openPanel, setOpenPanel] = useState(true);

  const panel = useRef<HTMLDivElement>(null);

  const arrow = useRef<HTMLImageElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSign = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleAccordion = () => {
    if (panel.current && arrow.current) {
      if (panel.current.style.maxHeight > '0px') {
        panel.current.style.maxHeight = '0px';
        panel.current.classList.remove('open');
        arrow.current.style.rotate = '180deg';
        setOpenPanel(false);
      } else {
        panel.current.style.maxHeight = panel.current.scrollHeight + 'px';
        panel.current.classList.add('open');
        arrow.current.style.rotate = '0deg';
        setOpenPanel(true);
      }
    }
  };

  return (
    <DialogLayout open={true}>
      <div className='w-full max-w-[536px] px-[50px] py-[45px] bg-white rounded-[15px] flex flex-col items-center relative'>
        <div
          className='absolute top-[25px] right-[25px] rounded-full size-[30px] bg-[#0000000D] cursor-pointer grid place-items-center'
          onClick={handleClose}
        >
          <img src={ASSETS.cross} alt='' />
        </div>
        <Typography
          variant='h2'
          className={twMerge(
            'text-center transition-all',
            !openPanel && 'mt-[53px]'
          )}
        >
          Signature Request
        </Typography>
        <Typography
          variant='h5'
          className={twMerge(
            'text-center mt-[10px] transition-all',
            openPanel ? 'mb-10' : 'mb-[95px]'
          )}
        >
          Only sign this message if you fully understand the content and trust
          the requesting site.
        </Typography>
        <div className='w-full'>
          <div
            className='border-t border-k-border w-full h-12 flex items-center justify-between cursor-pointer'
            onClick={handleAccordion}
          >
            <Typography variant='body1' className='font-semibold'>
              Signature Details
            </Typography>
            <img
              src={ASSETS.chevron_down}
              className='transition-all duration-300'
              ref={arrow}
              alt=''
            />
          </div>
          <div
            ref={panel}
            className='panel max-h-0 overflow-hidden transition-all duration-300'
          >
            <Typography variant='body1' color='grey'>
              Schema:
            </Typography>
            <Typography variant='h6' className='break-words'>
              0x7857669b61110d06c2188f9b218bb90facf96ffe2ec59d9657dfd1b529c23e7a
            </Typography>
            <Typography variant='body1' color='grey' className='mt-[10px]'>
              Recipient:
            </Typography>
            <Typography variant='h6' className='break-words'>
              Reserves
            </Typography>
            <Typography variant='body1' color='grey' className='mt-[10px]'>
              Time:
            </Typography>
            <Typography variant='h6' className='break-words'>
              1722368046
            </Typography>
            <Typography variant='body1' color='grey' className='mt-[10px]'>
              ExpirationTime:
            </Typography>
            <Typography variant='h6' className='break-words'>
              0
            </Typography>
            <Typography variant='body1' color='grey' className='mt-[10px]'>
              Revocable:
            </Typography>
            <Typography variant='h6' className='break-words'>
              False
            </Typography>
            <Typography variant='body1' color='grey' className='mt-[10px]'>
              Ref UID:
            </Typography>
            <Typography variant='h6' className='break-words'>
              0x0000000000000000000000000000000000000000000000000000000000000000
            </Typography>
            <div className='h-5'></div>
          </div>
        </div>

        <div className='bg-k-grey-200 px-[18px] pt-[17px] pb-[21px] w-full rounded-lg flex items-center justify-between'>
          <div>
            <Typography variant='body1' color='grey'>
              File Name:
            </Typography>
            <Typography variant='body1' className='font-semibold'>
              Doc-Title-Ipsum-Majinade.pdf
            </Typography>
          </div>
          <div>
            <Typography variant='body1' color='grey'>
              Version:
            </Typography>
            <Typography variant='body1' className='font-semibold'>
              1
            </Typography>
          </div>
          <div>
            <Typography variant='body1' color='grey'>
              File Size:
            </Typography>
            <Typography variant='body1' className='font-semibold'>
              238K
            </Typography>
          </div>
        </div>
        <div className='flex items-center gap-[15px] w-full mt-[35px]'>
          <Button label='Reject' className='flex-1' onClick={handleClose} />
          <Button
            label='Accept & Sign'
            variant='primary'
            className='flex-1'
            onClick={handleSign}
          />
        </div>
      </div>
    </DialogLayout>
  );
};

export default SignatureRequest;
