import React, { FC, TextareaHTMLAttributes, useId } from 'react';

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

const Textarea: FC<IProps> = ({ value, label, ...rest }) => {
  const id = useId();
  return (
    <label htmlFor={id}>
      <div className='w-full max-w-[488px]  min-h-[65px] border border-k-input-border flex flex-col p-3 rounded-[12px] relative group focus-within:border-k-black focus-within:shadow-border has-[input:not(:placeholder-shown)]:border-k-black has-[input:not(:placeholder-shown)]:shadow-border'>
        <textarea
          className='w-full peer border border-k-black outline-none border-none text-lg mt-5 placeholder:text-transparent resize-none'
          placeholder='hi'
          value={value}
          rows={3}
          {...rest}
        ></textarea>
        <p
          className='text-lg text-k-grey-text absolute top-[16px] peer-[:focus]:text-sm peer-[:focus]:translate-y-0 peer-[:focus]:top-2
  peer-[:not(:placeholder-shown)]:text-sm  peer-[:not(:placeholder-shown)]:top-2 transition-all duration-200 ease
  pointer-events-none'
        >
          {label}
        </p>
      </div>
    </label>
  );
};

export default Textarea;
