import { apiUrl } from '../app.config';
import axios from 'axios';
import CONSTANTS from '../contants';
import { IAttessation, IUser } from '../types/interfaces';

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem(CONSTANTS.token);
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response && error?.response?.status === 401) {
      localStorage.clear();
      if (window.location.pathname !== '/') window.location.pathname = '/';
    }
    return Promise.reject(error);
  }
);

export default api;

export const onBoarding = (token: string) =>
  api.post<{ message: string; access_token: string }>('/v1/user/onboarding', {
    token,
  });

export const createHashWithDoc = (data: FormData) =>
  api.post<{ hash: string }>('/v1/user/uploadDocToCreateHash', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const createHashWithText = (text: string) =>
  api.post<{ hash: string }>('/v1/user/uploadTextToCreateHash ', { text });

export const getCurrentUser = () => api.get<{ user: IUser }>('/v1/user/');

export const getAllAttesations = () =>
  api.get<{ message: string; attestationData: IAttessation[] }>(
    '/v1/attestation/allAttestations'
  );

export const getAttestationByUid = (uid: string) =>
  api.get<{
    message: string;
    attestationData: IAttessation;
    isUserAttestation: boolean;
    userData: IUser;
  }>(`/v1/attestation/inspect/${uid}`);

export const compareDocument = (data: FormData) =>
  api.post<{
    documentComparisonResult: boolean;
    hash: string;
    message: string;
  }>('/v1/attestation/compareDocument', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const addNewAttestaion = (data: FormData) =>
  api.post('/v1/attestation/addNewAttestation', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
