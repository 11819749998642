import React from 'react';

import { Outlet } from 'react-router-dom';
import Typography from '../../components/Typography/Typography';

import ASSETS from '../../assets';

const AuthLayout = () => {
  return (
    <div className='min-h-screen bg-white flex flex-col'>
      <div className='h-[168px] pb-[51px] flex items-center justify-center gap-5 bg-auth_gradient'>
        <img src={ASSETS.kpmg_logo} alt='' />
        <Typography variant='h3' color='white'>
          +
        </Typography>
        <img src={ASSETS.kresus_logo} alt='' />
      </div>
      <div className='flex-grow flex flex-col  w-full bg-white pb-[104px]'>
        <div className='flex-grow bg-white w-full shadow-auth_box rounded-[15px] max-w-[980px] mx-auto mt-[-51px] flex flex-col'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
