import { useEffect, useRef, FC } from 'react';
import Typography from '../Typography/Typography';
import { twMerge } from 'tailwind-merge';
import ASSETS from '../../assets';
import { IStepperConfig } from '../../types/interfaces';

interface IProps {
  activeIndex: number;
  steps: IStepperConfig[];
}

const Stepper: FC<IProps> = ({ activeIndex, steps }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      if (activeIndex === 0) {
        ref.current.style.width = `calc(${
          (100 / (steps.length - 1)) * activeIndex
        }% + 19px)`;
      } else {
        ref.current.style.width = `calc(${
          (100 / (steps.length - 1)) * activeIndex
        }% + 10px)`;
      }
    }
    // eslint-disable-next-line
  }, [activeIndex]);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.width = '19px';
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className='h-[19px] bg-k-grey-500 rounded-3xl relative '>
        <div className='absolute top-0 left-0 h-full w-full flex items-center justify-between'>
          {steps.map((step, i) => (
            <div
              className={twMerge(
                'flex flex-col items-center z-10 w-[1px]',
                i === 0 && 'items-start',
                i === steps.length - 1 && 'items-end'
              )}
            >
              <div className='absolute top-0 size-[19px]  rounded-full p-[2px]'>
                <div className='bg-white w-full h-full rounded-full grid place-items-center'>
                  {/* {activeIndex > i && <img src={ASSETS.check} alt='' />} */}
                  {step.finished && <img src={ASSETS.check} alt='' />}
                </div>
              </div>
              <div className='absolute top-[25px]'>
                <Typography
                  variant='body1'
                  //   color={activeIndex > i ? 'blueDark' : 'grey'}
                  color={step.finished ? 'blueDark' : 'grey'}
                  //   className={twMerge(
                  //     activeIndex > i ? 'font-semibold' : 'font-normal'
                  //   )}
                  className={twMerge(
                    step.finished ? 'font-semibold' : 'font-normal'
                  )}
                >
                  {/* {activeIndex > i ? step.successText : step.text} */}
                  {step.finished ? step.successText : step.text}
                </Typography>
              </div>
            </div>
          ))}
          <div
            ref={ref}
            className='absolute top-0 left-0  max-w-[100%] h-full bg-stepper_gradient rounded-3xl transition-all duration-300'
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
