import { Extension, Command, CommandProps } from '@tiptap/core';
import { Node as ProseMirrorNode } from 'prosemirror-model';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    indent: {
      indent: () => ReturnType;
      outdent: () => ReturnType;
    };
  }
}

const Indent = Extension.create({
  name: 'indent',

  addCommands() {
    return {
      indent:
        (): Command =>
        ({ editor }: CommandProps) => {
          const { $from } = editor.state.selection;
          const currentNode: ProseMirrorNode = $from.node($from.depth);

          // Ensure the node is a paragraph
          if (currentNode.type.name !== 'paragraph') {
            return false;
          }

          const marginLeft = parseInt(
            currentNode.attrs.style?.match(/margin-left: (\d+)px/)?.[1] || '0',
            10
          );

          return editor
            .chain()
            .focus()
            .updateAttributes('paragraph', {
              style: `margin-left: ${marginLeft + 20}px`,
            })
            .run();
        },
      outdent:
        (): Command =>
        ({ editor }: CommandProps) => {
          const { $from } = editor.state.selection;
          const currentNode: ProseMirrorNode = $from.node($from.depth);

          // Ensure the node is a paragraph
          if (currentNode.type.name !== 'paragraph') {
            return false;
          }

          const marginLeft = parseInt(
            currentNode.attrs.style?.match(/margin-left: (\d+)px/)?.[1] || '0',
            10
          );

          if (marginLeft > 0) {
            return editor
              .chain()
              .focus()
              .updateAttributes('paragraph', {
                style: `margin-left: ${marginLeft - 20}px`,
              })
              .run();
          }

          return false;
        },
    };
  },
});

export default Indent;
