import React, {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useId,
  useState,
} from 'react';

import Chip from '../Chip/Chip';
import { twMerge } from 'tailwind-merge';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  showButton?: boolean;
  tags?: string[];
  onTagChange?: (v: string) => void;
}

const TagsField: FC<IProps> = ({
  value,
  label,
  tags,
  onTagChange,
  ...rest
}) => {
  const id = useId();

  const [val, setVal] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.currentTarget.value);
  };

  return (
    <div className='w-full max-w-[488px]  min-h-[65px] border border-k-input-border flex flex-col p-3 rounded-[12px] relative group focus-within:border-k-black focus-within:shadow-border'>
      {!!tags?.length && (
        <div className='flex items-center gap-[5px] mt-5 flex-wrap'>
          {tags?.map(tag => (
            <Chip
              label={tag}
              onDelete={() => {
                onTagChange && onTagChange(tag);
              }}
            />
          ))}
        </div>
      )}
      <input
        className='w-full peer border border-k-black outline-none border-none text-lg mt-auto placeholder:text-transparent'
        placeholder='hi'
        value={val}
        onChange={handleChange}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (typeof onTagChange === 'function') onTagChange(val);
            setVal('');
          }
        }}
        onBlur={() => {
          if (val) {
            const list = val.split(',');
            if (typeof onTagChange === 'function') {
              list.forEach(tag => {
                onTagChange(tag);
              });
            }
            setVal('');
          }
        }}
        {...rest}
        id={id}
      />
      <p
        className={twMerge(
          'text-lg text-k-grey-text absolute top-[18px]  peer-[:focus]:text-sm peer-[:focus]:translate-y-0 peer-[:focus]:top-2 peer-[:not(:placeholder-shown)]:text-sm  peer-[:not(:placeholder-shown)]:top-2 transition-all duration-200 ease pointer-events-none',
          tags?.length && 'top-2 text-sm'
        )}
      >
        {label}
      </p>
    </div>
  );
};

export default TagsField;
