import React from 'react';
import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';
import ASSETS from '../../../assets';

const KPMGScan = () => {
  return (
    <div>
      <div className='w-full overflow-auto'>
        <table className='w-full border-collapse'>
          <thead>
            <tr className='[&_th]:text-sm [&_th]:text-k-grey-text [&_th]:font-medium [&_th]:text-left [&_th]:border-b [&_th]:border-b-k-border [&_th]:h-[37px]'>
              <th className='pl-[15px]'>UID</th>
              <th>Schema</th>
              <th>Creator</th>
              <th>Type</th>
              <th className='pr-[15px]'>Age</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 8 }, (_, i) => i).map(i => (
              <tr
                key={i}
                className='[&_td]:h-[55px] [&_td]:border-b [&_td]:border-b-k-border [&:last-of-type_td]:border-none'
              >
                <td className='pl-[15px]'>
                  <Typography className='truncate w-[27ch] text-k-dark-blue'>
                    0x723da0384482bb9416b10b45673517e18bea283a372c30a1c46bda4798ca5d65
                  </Typography>
                </td>
                <td>
                  <div className='flex'>
                    <div className='bg-k-dark-blue text-sm text-white px-[5px] py-[2px] rounded-l-[4px]'>
                      #145
                    </div>
                    <div className='text-sm text-k-blue font-semibold px-[5px] py-[1px] border border-k-light-blue-300 bg-[#D4EBFF] rounded-r'>
                      Endorsements
                    </div>
                  </div>
                </td>
                <td>
                  <Typography className='truncate w-[27ch] text-k-dark-blue'>
                    attestations.icebreakerlabs.eth
                  </Typography>
                </td>
                <td>
                  <Typography variant='h6' color='grey' className=''>
                    On Chain
                  </Typography>
                </td>
                <td>
                  <Typography variant='h6' color='grey' className=''>
                    31m ago
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='flex justify-center mt-10'>
        <Button label='View All Attestations' leftIcon={ASSETS.add_black} />
      </div>
    </div>
  );
};

export default KPMGScan;
