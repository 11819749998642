import React, { FC, useEffect, useCallback } from 'react';

import ClickAwayListener from 'react-click-away-listener';
import { Editor } from '@tiptap/react';

import ColorPicker from './ColorPicker';
import ASSETS from '../../assets';

interface IProps {
  editor: Editor;
}

const HighlightC: FC<IProps> = ({ editor }) => {
  const [color, setColor] = React.useState('#ffffff00');
  const [open, setOpen] = React.useState(false);

  const togglePicker = () => {
    setOpen(!open);
  };

  const onColorChange = useCallback(
    (clr: string) => {
      setColor(clr);
      editor.commands.toggleHighlight({ color: clr });
    },
    [editor]
  );

  const handleClose = () => setOpen(false);

  useEffect(() => {
    editor?.chain().focus().run();

    editor.on('selectionUpdate', ({ editor }) => {
      const info = editor.getAttributes('highlight');
      if (info.color) {
        setColor(info.color);
      } else {
        setColor('#ffffff00');
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className='relative'>
      <div
        className='flex items-center gap-1 cursor-pointer'
        onClick={togglePicker}
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className='h-6 w-6 grid place-items-center'>
          <div
            className='h-[18px] w-[18px] border-2 border-[#E1E1E2] rounded'
            style={{ background: color }}
          ></div>
        </div>
        <img src={ASSETS.tri_down} alt='' />
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className='absolute z-10 right-0'>
            <ColorPicker onColorChange={onColorChange} />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default HighlightC;
