import { Models } from '@rematch/core';

import { auth } from './auth';
import { attesation } from './attesation';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  attesation: typeof attesation;
}

export const models: RootModel = {
  auth,
  attesation,
};
