import { createModel } from '@rematch/core';
import { ATTESATION } from '../../types/types';

import { RootModel } from '.';
import {
  createHashWithDoc,
  createHashWithText,
  getAllAttesations,
} from '../../http';

import { IAttessation } from '../../types/interfaces';

interface IState {
  attesationType: ATTESATION;
  doc_hash: string;
  text_hash: string;
  loading: boolean;
  allAttesations: IAttessation[];
  selectedAttestation: IAttessation | null;
}

export const attesation = createModel<RootModel>()({
  name: 'attesation',
  state: {
    attesationType: 'text',
    doc_hash: '',
    text_hash: '',
    loading: false,
    allAttesations: [],
    selectedAttestation: null,
  } as IState,
  reducers: {
    setAttesationType(state, payload: ATTESATION) {
      state.attesationType = payload;
    },
    setDocHash(state, payload: string) {
      state.doc_hash = payload;
    },
    setTextHash(state, payload: string) {
      state.text_hash = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setAllAttesations(state, payload: IAttessation[]) {
      state.allAttesations = payload;
    },
    setSelectedAttestation(state, payload: IAttessation | null) {
      state.selectedAttestation = payload;
    },
  },
  effects: dispatch => ({
    async handleGenerateDocHash(payload: FormData) {
      try {
        dispatch.attesation.setLoading(true);
        const { data } = await createHashWithDoc(payload);
        dispatch.attesation.setDocHash(data.hash);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.attesation.setLoading(false);
      }
    },
    async handleGenerateTextHash(payload: string) {
      try {
        dispatch.attesation.setLoading(true);
        const { data } = await createHashWithText(payload);
        dispatch.attesation.setTextHash(data.hash);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.attesation.setLoading(false);
      }
    },
    async handleGetAllAttesations() {
      try {
        dispatch.attesation.setLoading(true);
        const { data } = await getAllAttesations();
        dispatch.attesation.setAllAttesations(data.attestationData);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.attesation.setLoading(false);
      }
    },
  }),
});
