const ASSETS = {
  kresus_logo: require('./icons/kresus-logo.svg').default,
  kpmg_logo: require('./icons/kpmg-logo.svg').default,
  arrow_right_white: require('./icons/arrow-right.svg').default,
  arrow_left: require('./icons/arrow-left.svg').default,
  settings: require('./icons/settings.svg').default,
  add: require('./icons/add.svg').default,
  search: require('./icons/search.svg').default,
  add_black: require('./icons/add-black.svg').default,
  add_blue: require('./icons/add-blue.svg').default,
  arrow_up_blue: require('./icons/arrow_up_blue.svg').default,
  info_grey: require('./icons/info-grey.svg').default,
  doc: require('./img/doc.png'),
  docx: require('./icons/docx.svg').default,
  arrow_up: require('./icons/arrow-up.svg').default,
  copy_icon_blue: require('./icons/copy-blue.svg').default,
  doc_preview: require('./img/doc-preview.png'),
  upload_files: require('./icons/upload-files.svg').default,
  doc_icon: require('./icons/doc-icon.svg').default,
  close_icon: require('./icons/close-icon.svg').default,
  doc_icon_white: require('./icons/doc-icon-white.svg').default,
  check: require('./icons/check.svg').default,
  copy_black: require('./icons/copy-black.svg').default,
  chevron_down: require('./icons/chevron-down.svg').default,
  cross: require('./icons/cross.svg').default,
  edge: require('./icons/edge.svg').default,
  undeo: require('./icons/undo.svg').default,
  redo: require('./icons/redo.svg').default,
  bold: require('./icons/bold.svg').default,
  italic: require('./icons/Italic.svg').default,
  underline: require('./icons/Underline.svg').default,
  strike: require('./icons/Strikethrough.svg').default,
  text_color: require('./icons/TextColor.svg').default,
  align_left: require('./icons/AlignLeft.svg').default,
  align_center: require('./icons/AlignCenter.svg').default,
  align_right: require('./icons/AlignRight.svg').default,
  justify_text: require('./icons/JustifyText.svg').default,
  spacing: require('./icons/Spacing.svg').default,
  indent_left: require('./icons/Indent Left.svg').default,
  indent_right: require('./icons/IndentRight.svg').default,
  remove_style: require('./icons/RemoveStyle.svg').default,
  tri_down: require('./icons/tri-down.svg').default,
  cross_red: require('./icons/cross-red.svg').default,
  x_grey: require('./icons/x-grey.svg').default,
  user: require('./icons/user.svg').default,
  upload: require('./icons/upload.svg').default,
  tick: require('./icons/tick.svg').default,
  tick_small: require('./icons/tick-small.svg').default,
  doc_green: require('./icons/doc_success.svg').default,
  cross_large: require('./icons/cross_large.svg').default,
  pdf: require('./icons/pdf.svg').default,
  text: require('./icons/text.svg').default,
};

export default ASSETS;
