import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';
import { useNavigate } from 'react-router-dom';

import Typography from '../../../components/Typography/Typography';
import Chip from '../../../components/Chip/Chip';
import Button from '../../../components/Button/Button';

import ASSETS from '../../../assets';
import { IAttessation } from '../../../types/interfaces';
import CompareAttestation from '../../../dialogs/CompareAttestation/CompareAttestation';

const AllAttesations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [open, setOpen] = useState(false);

  const { allAttesations } = useSelector(
    (state: RootState) => state.attesation
  );

  const handleAttestationClick = (attesation: IAttessation) => {
    if (attesation.attestationType === 'doc') {
      dispatch.attesation.setSelectedAttestation(attesation);
      setOpen(true);
    } else {
      dispatch.attesation.setSelectedAttestation(attesation);
      navigate('/inspect-text-attestation');
    }
  };

  useEffect(() => {
    dispatch.attesation.handleGetAllAttesations();
  }, []);
  return (
    <div className='flex flex-col'>
      {allAttesations.map(attesation => (
        <div
          key={attesation.UID}
          className='flex items-center justify-between gap-[30px] px-5 pt-6 pb-[30px] border-b border-b-k-border first-of-type:border-t first-of-type:border-t-k-border
          hover:border-none hover:shadow-auth_box cursor-pointer'
          onClick={() => handleAttestationClick(attesation)}
        >
          <div className='flex items-start gap-5 flex-grow'>
            <div className='size-[50px] min-w-[50px] rounded-lg border border-k-border overflow-hidden relative'>
              <img src={ASSETS.doc} alt='' />
              {attesation.attestationType === 'doc' && (
                <>
                  {attesation.documentType === 'pdf' ? (
                    <img
                      src={ASSETS.pdf}
                      className='absolute right-0 bottom-0'
                      alt=''
                    />
                  ) : (
                    <img
                      src={ASSETS.docx}
                      className='absolute right-0 bottom-0'
                      alt=''
                    />
                  )}
                </>
              )}
              {attesation.attestationType === 'text' && (
                <img
                  src={ASSETS.text}
                  className='absolute right-0 bottom-0'
                  alt=''
                />
              )}
              {/* <img
                src={ASSETS.docx}
                className='absolute right-0 bottom-0'
                alt=''
              /> */}
            </div>
            <div>
              <Typography variant='h5'>{attesation.name}</Typography>
              <Typography
                variant='h6'
                color='grey'
                className='line-clamp-2 mt-[10px]'
              >
                {attesation.description}
              </Typography>

              {attesation.tags.length > 0 && (
                <div className='flex items-center gap-2 mt-[18px] flex-wrap'>
                  {attesation.tags.map(t => (
                    <Chip label={t} key={t} />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className='min-w-[195px]'>
            <div className='flex items-center justify-between'>
              <Typography variant='body1' color='grey'>
                Last Modified:
              </Typography>
              <Typography variant='body1' className='font-bold'>
                {attesation.lastModified}
              </Typography>
            </div>
            <div className='flex items-center justify-between mt-1'>
              <Typography variant='body1' color='grey'>
                Versions:
              </Typography>
              <Typography variant='body1' className='font-bold'>
                {attesation.version}
              </Typography>
            </div>
            <a
              href={attesation.verifyOnEAS}
              rel='noopener noreferrer'
              target='_blank'
            >
              <Button
                label='Verify on EAS'
                className='w-full justify-center mt-[11px]'
                rightIcon={ASSETS.arrow_up}
              />
            </a>
            <div className='text-center mt-3'>
              <div className='items-center gap-2 inline-flex mx-auto cursor-pointer'>
                <Typography
                  variant='body1'
                  className='text-k-dark-blue font-semibold'
                >
                  Copy UID
                </Typography>
                <img src={ASSETS.copy_icon_blue} alt='' />
              </div>
            </div>
          </div>
        </div>
      ))}
      {open && <CompareAttestation open={open} setOpen={setOpen} />}
    </div>
  );
};

export default AllAttesations;
