import React, { FC } from 'react';

import { useDropzone } from 'react-dropzone';
import Typography from '../Typography/Typography';
import ASSETS from '../../assets';

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const FilePicker: FC<IProps> = ({ setShow, setFile }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    onDropAccepted: files => {
      if (files.length) {
        const file = files[0];
        setFile(file);
      }
      setShow(false);
    },
  });
  return (
    <div className='w-full h-full bg-k-dark-blue-85 rounded-[10px] border-2 border-k-dark-blue'>
      <div
        className='h-full w-full flex flex-col items-center justify-center gap-6'
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <img src={ASSETS.upload_files} className='w-11' alt='' />
        <Typography variant='h5' color='white'>
          Drop file here to upload.
        </Typography>
      </div>
    </div>
  );
};

export default FilePicker;
