import React, { FC } from 'react';

interface IProps {
  onColorChange: (clg: string) => void;
}

const colors = [
  '#212529',
  '#845ef7',
  '#339af0',
  '#22b8cf',
  '#51cf66',
  '#fcc419',
  '#ff6b6b',
  '#f06595',
  '#ffffff',
  '#5f3dc4',
  '#1864ab',
  '#0b7285',
  '#2b8a3e',
  '#e67700',
  '#c92a2a',
  '#c2255c',
  '#00000000',
];

const ColorPicker: FC<IProps> = ({ onColorChange }) => {
  const handleClick = (clr: string) => {
    onColorChange(clr);
  };

  return (
    <div
      className='shadow-auth_box border border-k-border rounded'
      onMouseDown={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className='w-[198px] bg-k-grey-200 flex items-center p-1 flex-wrap gap-2'>
        {colors.map(c => (
          <div
            key={c}
            className='size-5 cursor-pointer rounded  border border-k-border'
            style={{ background: c }}
            onClick={() => {
              handleClick(c);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
