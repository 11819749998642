import React, { FC, useState, useCallback, useEffect } from 'react';
import { Editor } from '@tiptap/react';
import ClickAwayListener from 'react-click-away-listener';
import ColorPicker from './ColorPicker';

interface IProps {
  editor: Editor;
}

const TextColor: FC<IProps> = ({ editor }) => {
  const [open, setOpen] = useState(false);
  const [color, setColor] = React.useState('#000000');

  const handleClose = () => setOpen(false);

  const toggleMenu = () => setOpen(!open);

  const onColorChange = useCallback(
    (clr: string): void => {
      setColor(clr);
      editor.commands.setColor(clr);
    },
    [editor]
  );

  useEffect(() => {
    editor.on('selectionUpdate', ({ editor }) => {
      const color = editor.getAttributes('textStyle').color;
      if (color) {
        setColor(color);
      } else {
        setColor('#000000');
      }
    });
  }, [editor]);

  return (
    <div className='relative'>
      <div className='cursor-pointer' onClick={toggleMenu}>
        <svg
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M3.5 20C3.5 19.4477 3.94772 19 4.5 19H20.5C21.0523 19 21.5 19.4477 21.5 20C21.5 20.5523 21.0523 21 20.5 21H4.5C3.94772 21 3.5 20.5523 3.5 20Z'
            fill={color}
          />
          <path
            d='M15.996 14H9.004L7.65544 17.3714C7.50358 17.7511 7.13588 18 6.72699 18C6.01954 18 5.5358 17.2855 5.79854 16.6287L11.2486 3.62861C11.4004 3.24895 11.7681 3 12.177 3H12.823C13.2319 3 13.5996 3.24895 13.7514 3.62861L19.2015 16.6287C19.4642 17.2855 18.9805 18 18.273 18C17.8641 18 17.4964 17.7511 17.3446 17.3714L15.996 14ZM15.196 12L12.5 5.885L9.804 12H15.196Z'
            fill='#52525B'
          />
        </svg>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className='absolute z-10 right-0'>
            <ColorPicker onColorChange={onColorChange} />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default TextColor;
