import React from 'react';
import Typography from '../../components/Typography/Typography';

const Logging = () => {
  return (
    <div className='flex flex-col items-center justify-center flex-grow'>
      <img
        src='https://s3-alpha-sig.figma.com/img/8c2c/e273/eabd7b79a72ee4614b83b00de79d2bf9?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lGnqZZKusD9niElE456zyUAJmIgEzTiLtQctbTywCK2suylcjYSahLqhzAiA9TqCQjILz-Tz6EI4c46qNlqeAYGzjzHB8RDgVz54kjiZgWionOJ6L6EQxco3zfdDLcnXc6xKaz7a3h-2meuUjnyUwwH-DWbisMmD2mcV~f-Lmi5tRYXE-E-1CZX3oKyO~DJg~5tIFmgipzaPVvuDr0jf7kX9xj0iaFX16Ze2sHZ5IHjp1FdgJaSfE59C8ZR~VnVxHz0iWRXQq8qV~Cjfca-6Hee67aPCUjkK7uDgyrjev3akQkbe6rjZFHzJL9eXHXIylx5n5M44BQnydrOZi2kwUA__'
        alt=''
        className='size-[50px]'
      />
      <Typography variant='h3' className='mt-[30px] title'>
        Securely logging you in
      </Typography>
      <Typography
        variant='h5'
        color='grey'
        className='max-w-[35ch] text-center mt-[15px]'
      >
        If this is your first-time here, it may take a few moments as we deploy
        your account.
      </Typography>
    </div>
  );
};

export default Logging;
