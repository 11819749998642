import React, { FC, InputHTMLAttributes, useId } from 'react';
import ASSETS from '../../assets';
import { twMerge } from 'tailwind-merge';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  showButton?: boolean;
  onSubmit?: () => void;
  error?: string;
  buttonDisabled?: boolean;
}

const TextField: FC<IProps> = ({
  value,
  label,
  showButton,
  onSubmit,
  className,
  readOnly,
  error,
  buttonDisabled,
  ...rest
}) => {
  const id = useId();

  return (
    <label htmlFor={id}>
      <div
        className={twMerge(
          'w-full max-w-[488px]  h-[65px] border border-k-input-border flex flex-col p-3 rounded-[12px] relative group focus-within:border-k-black focus-within:shadow-border',
          className,
          readOnly &&
            'border-none focus-within:border-none focus-within:shadow-none bg-k-grey-200',
          error &&
            'border-k-error focus-within:shadow-border_error focus-within:border-k-error'
        )}
      >
        <input
          className='w-full peer border border-k-black outline-none border-none text-lg mt-auto placeholder:text-transparent bg-transparent'
          placeholder='hi'
          value={value}
          {...rest}
          id={id}
          readOnly={readOnly}
        />
        <p
          className={twMerge(
            `text-lg text-k-grey-text absolute top-[50%] translate-y-[-50%] peer-[:focus]:text-sm peer-[:focus]:translate-y-0 peer-[:focus]:top-2
      peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:top-2 transition-all duration-200 ease
      pointer-events-none`,
            error && 'text-k-error'
          )}
        >
          {label}
        </p>
        {showButton && value && !error && (
          <div
            className='size-10 absolute top-[50%] right-[10px] translate-y-[-50%] bg-k-dark-blue rounded-full grid place-items-center cursor-pointer'
            role='button'
            onClick={() => {
              if (typeof onSubmit === 'function' && !buttonDisabled) onSubmit();
            }}
          >
            <img src={ASSETS.arrow_right_white} alt='' />
          </div>
        )}
        {error && (
          <div className='size-10 absolute top-[50%] right-[10px] translate-y-[-50%] rounded-full grid place-items-center cursor-pointer'>
            <img src={ASSETS.cross_red} alt='' />
          </div>
        )}
      </div>
    </label>
  );
};

export default TextField;
