import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateOutlet = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  if (!isLoggedIn) {
    return <Navigate to='/' replace />;
  } else {
    return <Outlet />;
  }
};

export default PrivateOutlet;
