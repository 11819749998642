import React, { FC, useEffect, useState } from 'react';

import DialogLayout from '../DialogLayout';
import { IStepperConfig } from '../../types/interfaces';
import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import ASSETS from '../../assets';
import Stepper from '../../components/Stepper/Stepper';
import { sleep } from '../../utils';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  finalized: boolean;
}

const CONFIG: IStepperConfig[] = [
  {
    text: 'Hashing',
    successText: 'Hashed',
    finished: false,
  },
  {
    text: 'Metadata',
    successText: 'Metadata',
    finished: false,
  },
  {
    text: 'Signing',
    successText: 'Signed',
    finished: false,
  },
  {
    text: 'Creating',
    successText: 'Created',
    finished: false,
  },
  {
    text: 'Finalizing',
    successText: 'Finalized',
    finished: false,
  },
];

const GeneratingAttesation: FC<IProps> = ({
  open,
  setOpen,
  onClose,
  finalized,
}) => {
  const [config, setConfig] = useState(CONFIG);
  const [active, setActive] = useState(0);
  const [title, setTitle] = useState('Generating Attestation');
  const [description, setDescription] = useState('Document hashing……');

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleGenerateAttesation = async () => {
    await sleep(2000);
    setConfig(prev => {
      const newConfig = [...prev];
      newConfig[0].finished = true;
      return newConfig;
    });
    setDescription('Attaching metadata…');
    await sleep(2000);
    setActive(1);
    setConfig(prev => {
      const newConfig = [...prev];
      newConfig[1].finished = true;
      return newConfig;
    });
    setDescription('Signing the document…');
    await sleep(2000);
    setActive(2);
    setConfig(prev => {
      const newConfig = [...prev];
      newConfig[2].finished = true;
      return newConfig;
    });
    setDescription('Creating attestation…');
    await sleep(2000);
    setActive(3);
    setConfig(prev => {
      const newConfig = [...prev];
      newConfig[3].finished = true;
      return newConfig;
    });
    setDescription('Finalizing…');
    await sleep(2000);
    setActive(4);
  };

  useEffect(() => {
    setConfig(CONFIG.map(i => ({ ...i })));
    setActive(0);
    setTitle('Generating Attestation');
    setDescription('Document hashing……');
    handleGenerateAttesation();
  }, []);

  useEffect(() => {
    if (finalized && active === 4) {
      setTitle('Congratulations! ');
      setDescription(
        'Your document has been successfully verified on the blockchain. There is now a permanent and immutable record of its creation.'
      );
      setConfig(prev => {
        const newConfig = [...prev];
        newConfig[4].finished = true;
        return newConfig;
      });
    }
  }, [finalized, active]);

  return (
    <DialogLayout open={open}>
      <div className='w-full max-w-[536px] bg-white p-[25px] rounded-[15px] max-h-[95vh] overflow-auto aspect-square flex flex-col'>
        <Stepper activeIndex={active} steps={config} />
        <div className='flex-grow flex flex-col justify-between items-center'>
          <div />
          <div className='w-full items-center flex flex-col'>
            <Typography variant='h3' color='blue'>
              {title}
            </Typography>

            <Typography
              variant='h6'
              color='grey'
              className='text-center mt-[25px]'
            >
              {description}
            </Typography>
          </div>
          <div className='mb-10'>
            <Button
              leftIcon={ASSETS.doc_icon_white}
              label='Continue with Attestation'
              variant='primary'
              disabled={active !== 4}
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
    </DialogLayout>
  );
};

export default GeneratingAttesation;
