import React, { useState } from 'react';
import Typography from '../Typography/Typography';
import AttesationInfo from '../../dialogs/AttesationInfo/AttesationInfo';
import ASSETS from '../../assets';

const WhatIsAttesation = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  return (
    <div className='flex justify-center'>
      <div
        className='flex items-center gap-2 cursor-pointer'
        onClick={handleOpen}
      >
        <Typography variant='body1' color='grey'>
          What is an Attestation?
        </Typography>
        <img src={ASSETS.info_grey} alt='' />
      </div>
      {open && <AttesationInfo open={open} setOpen={setOpen} />}
    </div>
  );
};

export default WhatIsAttesation;
