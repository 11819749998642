import React, { FC } from 'react';

import DialogLayout from '../DialogLayout';
import CompareDoc from '../../pages/Comparison/CompareDoc';
import ASSETS from '../../assets';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompareAttestation: FC<IProps> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DialogLayout open={true}>
      <div className='w-full max-w-[905px] relative'>
        <div
          className='absolute top-[25px] right-[25px] rounded-full size-[30px] bg-[#0000000D] cursor-pointer grid place-items-center z-20'
          onClick={handleClose}
        >
          <img src={ASSETS.cross} alt='' />
        </div>
        <CompareDoc authorizedUser />
      </div>
    </DialogLayout>
  );
};

export default CompareAttestation;
