import React, { useState } from 'react';

import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AuthLayout from '../layouts/AuthLayout/AuthLayout';
import Login from '../pages/Login/Login';
import VerifyEmail from '../pages/VerifyEmail/VerifyEmail';
import Logging from '../pages/Logging/Logging';
import DashboardLayout from '../layouts/DashboardLayout/DashboardLayout';
import Documents from '../pages/Documents/Documents';
import AddDocument from '../pages/AddDocument/AddDocument';
import EditorProvider from '../contexts/EditorContext';
import InspectTextAttestation from '../pages/InspectTextAttestation/InspectTextAttestation';
import Comparison from '../pages/Comparison/Comparison';
import RedirectOutlet from './RedirectOutlet/RedirectOutlet';
import PrivateOutlet from './PrivateOutlet/PrivateOutlet';
import { RootState } from '../store';
import Typography from '../components/Typography/Typography';
import TextField from '../components/TextField/TextField';

const Index = () => {
  const [allow, setAllow] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  return (
    <EditorProvider>
      {!(isLoggedIn || allow) && (
        <div className='w-full h-full bg-white absolute top-0 left-0 z-40 flex items-center justify-center'>
          <div className='min-w-[400px] flex flex-col items-center gap-5'>
            <Typography variant='h2' color='blueDark'>
              Please Enter the Password
            </Typography>
            <div className='w-full'>
              <TextField
                label='Password'
                value={password}
                onChange={e => {
                  setPassword(e.currentTarget.value);
                  setError('');
                }}
                className='w-full'
                showButton
                error={error}
                onSubmit={() => {
                  setError('');
                  if (password === '12345admin/') {
                    setAllow(true);
                  } else {
                    setError('Invalid Password');
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}

      <Routes>
        <Route path='/' element={<RedirectOutlet />}>
          <Route path='/' element={<AuthLayout />}>
            <Route path='' index element={<Login />} />
            <Route path='verify-email' element={<VerifyEmail />} />
            <Route path='logging-in' element={<Logging />} />
          </Route>
        </Route>
        <Route path='compare-doc/:id' element={<Comparison />} />
        <Route path='/' element={<PrivateOutlet />}>
          <Route path='/' element={<DashboardLayout />}>
            <Route path='/documents' element={<Documents />} />
            <Route path='/add-document' element={<AddDocument />} />
            <Route
              path='/inspect-text-attestation'
              element={<InspectTextAttestation />}
            />
          </Route>
        </Route>
      </Routes>
    </EditorProvider>
  );
};

export default Index;
