import React, { createContext, useContext } from 'react';

import { Editor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Text from '@tiptap/extension-text';
import Color from '@tiptap/extension-color';
import TextAlign from '@tiptap/extension-text-align';
import CustomParagraph from '../components/Editor/CustomParagraph';
import Indent from '../components/Editor/Indent';
import Highlight from '@tiptap/extension-highlight';

type EditorContextType = Editor | null;

export const EditorContext = createContext<EditorContextType>(null);

export const useTipTap = () => useContext(EditorContext);

const EditorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        paragraph: false,
      }),
      Placeholder.configure({
        placeholder: 'Start writing...',
      }),
      Underline,
      Text,
      TextStyle,
      Color,
      TextAlign.configure({
        alignments: ['left', 'center', 'right', 'justify'],
        types: ['heading', 'paragraph'],
        defaultAlignment: 'left',
      }),
      CustomParagraph,
      Indent,
      Highlight.configure({
        multicolor: true,
      }),
    ],
    content: '',
  });
  return (
    <EditorContext.Provider value={editor}>{children}</EditorContext.Provider>
  );
};

export default EditorProvider;
