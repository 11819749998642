import React from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';

import ASSETS from '../../assets';
import Chip from '../../components/Chip/Chip';
import { shortenAddress } from '../../utils';

const InspectTextAttestation = () => {
  const { selectedAttestation } = useSelector(
    (state: RootState) => state.attesation
  );

  return (
    <div className='flex-grow bg-white shadow-auth_box rounded-[15px] px-[50px] pb-[50px]  flex flex-col mb-[81px]'>
      <div className='flex'>
        <Link
          to='/documents'
          className='flex items-center gap-2 cursor-pointer py-4 ml-[-14px]'
        >
          <img src={ASSETS.arrow_left} alt='arrow-left' />
          <Typography variant='body1' color='blue' className='font-[660]'>
            Dashboard
          </Typography>
        </Link>
      </div>
      <div className='flex gap-[50px] items-stretch flex-grow h-0'>
        <div className='flex flex-grow bg-k-grey-200 rounded-xl p-[22px] border border-solid border-k-grey-200 h-full'>
          <div className='flex flex-col bg-white pt-10 pl-10 pr-10 w-full gap-4'>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedAttestation?.text || '',
              }}
            ></div>
          </div>
        </div>
        <div className='flex flex-grow max-w-[328px] min-w-[328px]'>
          <div className='flex flex-col bg-white  w-full'>
            <Typography variant='h3' className='font-[382] leading-[30px]'>
              {selectedAttestation?.name}
            </Typography>
            <Typography
              variant='h6'
              color='black'
              className='leading-[19px] font-medium mt-[15px]'
            >
              {selectedAttestation?.description}
              {/* <div className='flex items-center gap-2 mt-2'>
                <Typography variant='body1' color='blue' className='font-[660]'>
                  Read More
                </Typography>
                <img src={ASSETS.add_blue} alt='add-blue' className='' />
              </div> */}
            </Typography>
            {selectedAttestation?.tags &&
              selectedAttestation?.tags.length > 0 && (
                <div className='flex items-center gap-2 mt-[22px]  mb-5 flex-wrap'>
                  {selectedAttestation.tags.map(t => (
                    <Chip label={t} key={t} />
                  ))}
                </div>
              )}
            <div className='w-full grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 mb-6'>
              <div className='flex w-full flex-col'>
                <Typography
                  variant='body1'
                  color='grey'
                  className='font-medium'
                >
                  Date Created:
                </Typography>
                <Typography variant='body1' className='font-[660]'>
                  {selectedAttestation?.dateCreated}
                </Typography>
              </div>
              <div className='flex w-full flex-col'>
                <Typography
                  variant='body1'
                  color='grey'
                  className='font-medium'
                >
                  Your Wallet Address:
                </Typography>
                <Typography
                  variant='body1'
                  className='flex items-center gap-2 font-[660]'
                >
                  {shortenAddress(selectedAttestation?.creator || '')}
                  <img
                    src={ASSETS.copy_black}
                    alt='copy_black'
                    className='cursor-pointer opacity-60'
                  />
                </Typography>
              </div>
              <div className='flex w-full flex-col'>
                <Typography
                  variant='body1'
                  color='grey'
                  className='font-medium'
                >
                  UID:
                </Typography>
                <Typography
                  variant='body1'
                  className='flex items-center gap-2 font-[660]'
                >
                  {shortenAddress(selectedAttestation?.UID || '')}
                  <img
                    src={ASSETS.copy_black}
                    alt='copy_black'
                    className='cursor-pointer opacity-60'
                  />
                </Typography>
              </div>
            </div>
            <div className='mt-auto'></div>
            <div className='flex flex-col w-full gap-3'>
              <Button
                label='Open Attestation in New Window'
                className='w-full justify-center py-2 text-sm'
                rightIcon={ASSETS.arrow_up}
              />
              <Button
                label='Print Attestation'
                className='w-full justify-center py-2 text-sm'
              />
              <div className='flex justify-center'>
                <a
                  href={selectedAttestation?.verifyOnEAS}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <div className='items-center gap-2 inline-flex mx-auto cursor-pointer'>
                    <Typography
                      variant='body1'
                      color='blue'
                      className='font-[660]'
                    >
                      Verify on EAS
                    </Typography>
                    <img src={ASSETS.arrow_up_blue} alt='' />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InspectTextAttestation;
