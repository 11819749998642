import React, { FC } from 'react';

interface IProps {
  color?: string;
}

const DocIcon: FC<IProps> = ({ color = '#086DE1' }) => {
  return (
    <svg
      width='13'
      height='15'
      viewBox='0 0 13 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_753_12171)'>
        <path
          d='M2.7742 14.8563C2.02354 14.8563 1.45622 14.6668 1.0739 14.287C0.691572 13.9073 0.5 13.3468 0.5 12.6058V2.25054C0.5 1.51434 0.691572 0.954728 1.0739 0.57332C1.45622 0.191107 2.02271 0 2.7742 0H5.96516V5.18811C5.96516 6.03881 6.39928 6.46457 7.2667 6.46457H12.5V12.6058C12.5 13.342 12.3084 13.9016 11.9261 14.2838C11.5438 14.666 10.9773 14.8571 10.2258 14.8571H2.7742V14.8563ZM7.39168 5.46227C7.12611 5.46227 6.99291 5.33164 6.99291 5.07118V0.0685404C7.15159 0.0870866 7.3111 0.150789 7.47225 0.260454C7.6334 0.370118 7.80031 0.512037 7.97215 0.686211L11.7921 4.48819C11.9738 4.67123 12.1193 4.84057 12.2295 4.99619C12.3389 5.15182 12.403 5.30745 12.4219 5.46307H7.39085L7.39168 5.46227Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_753_12171'>
          <rect x='0.5' width='12' height='15' rx='2.176' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DocIcon;
