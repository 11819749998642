import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IUser } from '../../types/interfaces';
import { getCurrentUser } from '../../http';
import CONSTANTS from '../../contants';

interface IState {
  isLoggedIn: boolean;
  tokenPromise: any;
  email: string;
  user: IUser | null;
  hashData: string;
  attesationType: 'text' | 'document';
  loading: boolean;
}

export const auth = createModel<RootModel>()({
  state: {
    isLoggedIn: !!localStorage.getItem(CONSTANTS.token),
    tokenPromise: null,
    email: '',
    user: null,
    hashData: '',
    attesationType: 'text',
    loading: false,
  } as IState,
  reducers: {
    setIsLoggedIn(state, payload: boolean) {
      state.isLoggedIn = payload;
    },
    setTokenPromise(state, payload: any) {
      state.tokenPromise = payload;
    },
    setEmail(state, payload: string) {
      state.email = payload;
    },
    setUser(state, payload: IUser | null) {
      state.user = payload;
    },
    setHashData(state, payload: string) {
      state.hashData = payload;
    },
    setAttesationType(state, payload: 'text' | 'document') {
      state.attesationType = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
  },
  effects: dispatch => ({
    async handleGetCurrentUser() {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await getCurrentUser();
        dispatch.auth.setUser(data.user);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
  }),
});
