import React, { FC, useEffect, useState } from 'react';

interface IProps {
  url: string | File;
}

const DocPreview: FC<IProps> = ({ url }) => {
  const [src, setSrc] = useState<string>('');

  useEffect(() => {
    if (typeof url === 'string') {
      // Append PDF toolbar control to the URL
      setSrc(url + '#toolbar=0');
    } else if (url instanceof File) {
      // Handle PDF blob URLs
      const mimeType = url.type;
      if (mimeType === 'application/pdf') {
        setSrc(URL.createObjectURL(url) + '#toolbar=0');
      } else {
        console.error('File is not a PDF');
        setSrc(''); // Clear src for unsupported file types
      }
    }
  }, [url]);

  return (
    <>
      {src ? (
        <iframe
          title='PDF Preview'
          src={src}
          className='w-full h-full pt-[22px] px-[22px] bg-transparent border-none outline-none'
        />
      ) : (
        <div className='w-full h-full flex items-center justify-center'>
          <p>Unable to preview this PDF.</p>
        </div>
      )}
    </>
  );
};

export default React.memo(DocPreview);
